body {
  background: #050011;
  height: 70vh;
}

#root {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.wrapper {
  display: grid;
  width: 100%;
  place-items: center;
}

p {
  margin: 0;
}

h1 {
  color: white
}

.lexical {
  width: 80%;
  border: 1px solid grey;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 2rem;
  border-radius: 5px;
}